<template>
  <div class="caseDataInfo" v-if="isShow">
    <div class="infoOne">
      <el-form
        :model="caseDataInfo"
        :rules="rulesCaseDataInfo"
        ref="rulesCaseDataInfo"
        label-width="120px"
      >
        <el-form-item label="招聘职位：" prop="jobTitle">
          <el-input
            v-model="caseDataInfo.jobTitle"
            style="width: 50%"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="职位类别：">
          <el-input
            v-model="caseDataInfo.jobWay"
            style="width: 50%"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="招聘方式：">
          <el-radio-group v-model="caseDataInfo.positionType">
            <dic-radio-group
              :code.sync="caseDataInfo.positionType"
              type-code="032"
            />
          </el-radio-group>
        </el-form-item>
        <el-form-item label="招聘类别：">
          <el-radio-group v-model="caseDataInfo.jobType">
            <dic-radio-group
              :code.sync="caseDataInfo.jobType"
              type-code="033"
            />
          </el-radio-group>
        </el-form-item>
        <el-form-item label="职位介绍：">
          <el-input
            v-model="caseDataInfo.jobDescription"
            placeholder="请输入"
            style="width: 50%"
            type="textarea"
            :autosize="{ minRows: 4, maxRows: 20 }"
          ></el-input>
        </el-form-item>
        <el-form-item label="企业名称：" prop="companyCode">
          <el-select
            v-model="caseDataInfo.companyCode"
            filterable
            allow-create
            remote
            reserve-keyword
            placeholder="请输入关键词"
            :remote-method="remoteMethod"
            :loading="loading"
            style="width: 50%"
            @change="changeCompany(caseDataInfo.companyCode)"
            :clearable="true"
          >
            <el-option
              v-for="(item, index) in options"
              :key="index"
              :label="item.companyFullName"
              :value="item.companyCode"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="技术类别：">
          <dic-checkbox-group
            :code.sync="caseDataInfo.technologyTypes"
            tag="技术"
            @updateCode="updateCode"
          />
        </el-form-item>
        <el-form-item label="领域类别：">
          <dic-checkbox-group
            :code.sync="caseDataInfo.neighborhoodTypes"
            tag="领域"
            @updateCode="updateCode"
          />
        </el-form-item>
        <el-form-item label="行业类别：">
          <dic-checkbox-group
            :code.sync="caseDataInfo.industryTypes"
            tag="行业"
            @updateCode="updateCode"
          />
        </el-form-item>
        <el-form-item label="发布时间：">
          <data-time
            @dateChange="dateClick"
            :parentYear="caseDataInfo.releaseTimeYear"
            :parentMonth="caseDataInfo.releaseTimeMonth"
            :parentDay="caseDataInfo.releaseTimeDay"
          />
        </el-form-item>
        <el-form-item label="发布地区：">
          <cascader-area
            :country="caseDataInfo.releaseAddressCountry"
            :province="caseDataInfo.releaseAddressProvince"
            :city="caseDataInfo.releaseAddressRegion"
            @searchCountry="searchCountry"
            @searchProvince="searchProvince"
            @searchCity="searchCity"
          />
        </el-form-item>
        <!-- 工作地址 输入 => 下拉 -->
        <!-- <el-form-item label="工作地址：">
          <cascader-area
            :country="caseDataInfo.releaseAddressCountry"
            :province="caseDataInfo.releaseAddressProvince"
            :city="caseDataInfo.releaseAddressRegion"
            @searchCountry="searchCountry"
            @searchProvince="searchProvince"
            @searchCity="searchCity"
          />
        </el-form-item> -->
        <el-form-item label="工作地址：">
          <el-input
            v-model="caseDataInfo.workAddress"
            style="width: 50%"
            placeholder="请输入"
          />
        </el-form-item>
        <el-form-item label="任职要求：">
          <el-input
            v-model="caseDataInfo.jobRequirements"
            style="width: 50%"
            type="textarea"
            :autosize="{ minRows: 4, maxRows: 20 }"
            placeholder="请输入"
          />
        </el-form-item>
        <el-form-item label="学历：">
          <el-input
            v-model="caseDataInfo.education"
            style="width: 50%"
            placeholder="请输入"
          />
        </el-form-item>
        <el-form-item label="工资：">
          <el-input
            v-model="caseDataInfo.wages"
            style="width: 50%"
            placeholder="请输入"
          />
        </el-form-item>
        <el-form-item label="招聘人数：">
          <el-input
            v-model="caseDataInfo.recruitNumber"
            style="width: 50%"
            placeholder="请输入"
          />
        </el-form-item>
        <el-form-item label="福利标签：">
          <dic-checkbox-group
            :code.sync="caseDataInfo.welfareLabels"
            type-code="034"
          />
        </el-form-item>
        <el-form-item label="福利待遇：">
          <el-input
            v-model="caseDataInfo.benefits"
            style="width: 50%"
            type="textarea"
            :autosize="{ minRows: 4, maxRows: 20 }"
            placeholder="请输入"
          />
        </el-form-item>
        <el-form-item label="工作经验：">
          <el-input
            v-model="caseDataInfo.workExperience"
            style="width: 50%"
            placeholder="请输入"
          />
        </el-form-item>
        <el-form-item label="招聘状态：">
          <el-radio-group v-model="caseDataInfo.jobStatus">
            <dic-radio-group
              :code.sync="caseDataInfo.jobStatus"
              type-code="035"
            />
          </el-radio-group>
        </el-form-item>
        <el-form-item label="关键词：">
          <el-input
            v-model="caseDataInfo.antistop"
            style="width: 50%"
            type="textarea"
            :autosize="{ minRows: 4, maxRows: 6 }"
          />
        </el-form-item>
        <el-form-item label="融资轮次：">
          <el-radio-group v-model="caseDataInfo.fundingRounds">
            <el-radio label="未融资">未融资</el-radio>
            <el-radio label="种子/天使轮">种子/天使轮</el-radio>
            <el-radio label="Pre-A/A轮">Pre-A/A轮</el-radio>
            <el-radio label="B轮">B轮</el-radio>
            <el-radio label="C轮">C轮</el-radio>
            <el-radio label="Pre-IPO">Pre-IPO</el-radio>
            <el-radio label="IPO">IPO</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="企业规模：">
          <el-radio-group v-model="caseDataInfo.companySize">
            <el-radio label="20人以下">20人以下</el-radio>
            <el-radio label="20-50人">20-50人</el-radio>
            <el-radio label="50-100人">50-100人</el-radio>
            <el-radio label="100-200人">100-200人</el-radio>
            <el-radio label="200-500人">200-500人</el-radio>
            <el-radio label="500-1000人">500-1000人</el-radio>
            <el-radio label="1000-2000人">1000-2000人</el-radio>
            <el-radio label="2000-5000人">2000-5000人</el-radio>
            <el-radio label="5000-10000人">5000-10000人</el-radio>
            <el-radio label="10000人以上">10000人以上</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="企业营收：">
          <el-input
            v-model="caseDataInfo.operatingIncome"
            style="width: 50%"
            placeholder="请输入"
          />
        </el-form-item>
        <el-form-item label="领域标签：" v-if="projectDataType">
          <el-input
            v-model="caseDataInfo.wisdomTag"
            style="width: 80%"
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 8 }"
          ></el-input>
        </el-form-item>
        <el-form-item label="行业标签：" v-if="projectDataType">
          <el-input
            v-model="caseDataInfo.IndustryTag"
            style="width: 80%"
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 8 }"
          ></el-input>
        </el-form-item>
        <el-form-item label="技术标签：" v-if="projectDataType">
          <el-input
            v-model="caseDataInfo.aiTag"
            style="width: 80%"
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 8 }"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="关键词：" v-if="projectDataType">
          <el-input
            v-model="caseDataInfo.antistop"
            style="width: 80%"
            type="textarea"
            :autosize="{ minRows: 4, maxRows: 6 }"
          />
        </el-form-item> -->
      </el-form>
      <div
        style="color: #7c7f8e; font-size: 13px; padding: 20px"
        v-if="projectDataType"
      >
        <p>创建人：{{ caseDataInfo.uploader }}</p>
        <p>修改人：{{ caseDataInfo.updateName }}</p>
        <p>更新时间：{{ caseDataInfo.updateTime }}</p>
        <p>收录时间：{{ caseDataInfo.uploadTime }}</p>
      </div>
    </div>
    <!-- <div class="bottom">
      <el-button type="primary" @click="saveItem">保存</el-button>
      <el-button @click="cancelItem">取消</el-button>
    </div> -->
  </div>
</template>

<script>
import UpLoadImage from "../../Product/UpLoadImage";
import DicRadioGroup from "@/components/DicRadioGroup.vue";
import {
  insertRecruitment,
  updateRecruitment,
  selectBYid,
} from "@/api/recruit";
import { findCompany, findPlan } from "@/api/caseData";
import CascaderArea from "@/components/CascaderArea.vue";
import DataTime from "@/components/DataTime.vue";
import DicCheckboxGroup from "@/components/DicCheckboxGroup.vue";
export default {
  name: "CaseDataInfo",

  components: {
    CascaderArea,
    DataTime,
    DicCheckboxGroup,
    DicRadioGroup,
    UpLoadImage,
  },

  data() {
    return {
      isShow: false,
      optionsAssociation: [],
      // 表单数据
      caseDataInfo: {
        jobTitle: "", //招聘职位
        jobWay: "", //职位类别
        positionType: "", //招聘方式
        jobType: "", //招聘类别
        jobDescription: "", //职位介绍
        companyCode: "", //公司Id
        companyName: "", //企业名称
        technologyTypes: [], //技术类别
        neighborhoodTypes: [], //领域类别
        industryTypes: [], //行业类别
        workAddress: "", //工作地址
        releaseTimeYear: null, //发布时间年
        releaseTimeMonth: null, //发布时间月
        releaseTimeDay: null, //发布时间日
        releaseAddressCountry: "", //发布国家
        releaseAddressProvince: "", //发布省份
        releaseAddressRegion: "", //发布城市
        jobRequirements: "", //任职要求
        education: "", //学历
        wages: "", //工资
        recruitNumber: "", //招聘人数
        welfareLabels: [], //福利标签
        workExperience: "", //工作经验
        jobStatus: "", //招聘状态
        keyword: "", //关键字
        benefits: "", //福利待遇
        fundingRounds: "", //融资轮次
        companySize: "", //企业规模
        operatingIncome: "", //企业营收
      },
      inputVisible: false,
      inputValue: "",
      imageList: [],
      options: [],
      options1: [],
      rulesCaseDataInfo: {
        jobTitle: [
          { required: true, message: "请输入招聘职位", trigger: "blur" },
        ],
        companyCode: [
          { required: true, message: "请填写公司名称", trigger: "blur" },
        ],
      },
      loading: false,
    };
  },
  computed: {
    projectDataType() {
      return Number(this.$route.query.projectDataType);
    },
    editInfo() {
      return Number(this.$route.query.id);
    },
  },

  watch: {
    "caseDataInfo.id": {
      handler(newVal) {
        if (this.caseDataInfo && newVal) {
          this.isShow = true;
        }
      },
      deep: true,
      immediate: true,
    },
  },

  created() {
    if (this.projectDataType) {
      this.search();
    }
    if (!this.projectDataType) {
      this.isShow = true;
    }
  },

  methods: {
    async search() {
      const res = await selectBYid({ id: this.editInfo });
      if (res.code == 200) {
        this.caseDataInfo = res.data;

        if (this.caseDataInfo.companyCode && this.caseDataInfo.companyName) {
          this.caseDataInfo.companyCode = res.data.companyCode;
          this.options = [
            {
              companyCode: res.data.companyCode,
              companyFullName: res.data.companyName,
            },
          ];
          // this.caseDataInfo.companyCode = res.data.companyCode
        }

        if (this.caseDataInfo.technologyType) {
          this.caseDataInfo.technologyTypes =
            this.caseDataInfo.technologyType.split(",");
        } else {
          this.caseDataInfo.technologyTypes = [];
        }
        if (this.caseDataInfo.neighborhoodType) {
          this.caseDataInfo.neighborhoodTypes =
            this.caseDataInfo.neighborhoodType.split(",");
        } else {
          this.caseDataInfo.neighborhoodTypes = [];
        }

        if (this.caseDataInfo.industryType) {
          this.caseDataInfo.industryTypes =
            this.caseDataInfo.industryType.split(",");
        } else {
          this.caseDataInfo.industryTypes = [];
        }

        if (this.caseDataInfo.welfareLabel) {
          this.caseDataInfo.welfareLabels =
            this.caseDataInfo.welfareLabel.split(",");
        } else {
          this.caseDataInfo.welfareLabels = [];
        }
      } else {
        this.$message.error("查询失败");
      }
    },
    formatNull(val) {
      if (val === null) {
        return [];
      } else {
        return val;
      }
    },
    // 将字符串格式化成数组
    formatString(val) {
      if (!val) {
        return [];
      } else if (!val.length) {
        return val;
      } else {
        return val.split(",");
      }
    },
    updateCode(val, tag) {
      switch (tag) {
        case "领域":
          this.caseDataInfo.wisdomLabelsChildren = val;
          break;

        case "技术":
          this.caseDataInfo.labelsChildren = val;
          break;

        case "行业":
          this.caseDataInfo.sectorssChildren = val;
          break;

        default:
          break;
      }
    },
    dateClick(year, month, day) {
      // this.caseDataInfo.releaseTimeYear = year;

      // this.caseDataInfo.releaseTimeMonth = month;
      this.caseDataInfo.releaseTimeYear = year;
      this.caseDataInfo.releaseTimeDay = day;
      this.caseDataInfo.releaseTimeMonth = month;
    },
    // 保存
    saveItem() {
      this.$refs.rulesCaseDataInfo.validate(async (valid) => {
        if (valid) {
          let res;

          if (this.caseDataInfo.wisdomLabelsChildren) {
            // 领域
            this.caseDataInfo.neighborhoodTypes =
              this.caseDataInfo.neighborhoodTypes.concat(
                this.caseDataInfo.wisdomLabelsChildren
              );
          }
          if (this.caseDataInfo.labelsChildren) {
            //  技术
            this.caseDataInfo.technologyTypes =
              this.caseDataInfo.technologyTypes.concat(
                this.caseDataInfo.labelsChildren
              );
          }
          if (this.caseDataInfo.sectorssChildren) {
            // 行业
            this.caseDataInfo.industryTypes =
              this.caseDataInfo.industryTypes.concat(
                this.caseDataInfo.sectorssChildren
              );
          }

          if (this.projectDataType) {
            res = await updateRecruitment(this.caseDataInfo);
          } else {
            res = await insertRecruitment(this.caseDataInfo);
          }

          if (res.code === 200) {
            this.$message.success(
              `${this.projectDataType ? "编辑" : "新增"}成功`
            );

            this.$router.go(-1);
          } else {
            this.$message.error(res.msg);
          }
        }
      });
    },
    // 取消
    cancelItem() {
      this.$confirm("确认退出吗 ?", "提示")
        .then(() => {
          this.$router.go(-1);
        })
        .catch(() => {});
    },
    searchCountry(val) {
      this.caseDataInfo.releaseAddressCountry = val;
    },
    searchProvince(val) {
      this.caseDataInfo.releaseAddressProvince = val;
    },
    searchCity(val) {
      this.caseDataInfo.releaseAddressRegion = val;
    },
    remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        setTimeout(async () => {
          this.loading = false;
          const companyName = query;
          const res = await findCompany({ companyName: companyName });
          this.options = res.data;
        }, 200);
      } else {
        this.options = [];
      }
    },
    async changeCompany(item1) {
      const res = await findPlan({ companyCode: item1 });
      if (res.code == 200) {
        this.optionsAssociation = res.data;
      }
    },
  },
  // mounted() {
  //   document.getElementsByClassName("infoOne")[0].style.height =
  //     document.body.clientHeight - 300 + "px";
  // },
};
</script>

<style lang="less" scoped>
.infoOne {
  overflow: auto;
}
.bottom {
  border-top: 1px solid #dbdbdb;
  background: #fff;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  .el-button {
    margin: 0 20px;
  }
}
.caseDataInfo {
  background-color: #fff;
  padding: 20px 20px 0 20px;
  .el-tag + .el-tag {
    margin-left: 10px;
  }
  .button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
  }
}
/deep/.el-upload--picture-card {
  width: 80px !important;
  height: 80px !important;
  line-height: 80px !important;
}
.policyData {
  display: flex;
  // justify-content: space-between;
}
</style>
