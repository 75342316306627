<template>
  <div>
    <div class="caseDataParent">
      <div class="caseDataParent_type">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="基本信息" name="first">
            <recruitInfo ref="recruitInfo" />
          </el-tab-pane>
          <el-tab-pane label="标签信息" name="second">
            <labelInfo ref="labelInfo" :addId="addId" :id="id" />
          </el-tab-pane>
          <el-tab-pane label="手动标签" name="third">
            <labelManagement ref="labelManagement" :addId="addId" :id="id" />
          </el-tab-pane>
          <el-tab-pane label="半自动标签" name="four">
            <robotizationlabel
              ref="robotizationlabel"
              :addId="addId"
              :id="id"
            />
          </el-tab-pane>
        </el-tabs>
        <div class="bottom" v-if="activeName !== 'four'">
          <el-button type="primary" @click="saveItem">保存</el-button>
          <el-button @click="cancelItem">取消</el-button>
        </div>
        <div
          style="height: 50px; background: #fff; border-top: 1px solid #EBEEF5"
          v-if="this.activeName == 'four'"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import recruitInfo from "./components/recruitInfo.vue";
import labelInfo from "./components/labelInfo.vue";
import labelManagement from "./components/labelManagement.vue";
import robotizationlabel from "./components/robotizationlabel.vue";
// 新增基本信息/修改基本信息
import { insertRecruitment, updateRecruitment, insertRecruitmentLabel, updateRecruitmentLabel, selectRecruitmentLabelDetail, insertRecruitmentLabelManual, updateRecruitmentLabelManual } from "@/api/recruit";
export default {
  name: "RecruitInfo",
  components: {
    recruitInfo,
    labelInfo,
    labelManagement,
    robotizationlabel
  },
  data() {
    return {
      activeName: "first", // 当前标签页
      addId: null // 新增数据唯一id
    };
  },
  computed: {
    // 0 新增 1 编辑 （点击新增/编辑按钮传过来的）
    projectDataType() {
      return Number(this.$route.query.projectDataType);
    },
    // 数据唯一id （点击编辑按钮传过来的）
    id() {
      return Number(this.$route.query.id);
    }
  },
  watch: {
    // 监听是否在手动标签页面
    activeName: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal && newVal == "third") {
          this.$refs.labelManagement.getBylabelId();
        }
      }
    }
  },
  created() {
    this.selectRecruitmentLabelDetail()//标签信息查询
  },
  methods: {
    // 切换tabs页(切换到手动标签,调用查询回显的数据)
    handleClick(tab, event) {
      if (tab.index == 2 && this.id) {
        this.$refs.labelManagement.getBylabelId();
      }
    },
    // 保存
    saveItem() {
      let res;
      switch (this.activeName) {
        case "first":
          this.$refs.recruitInfo.$refs.rulesCaseDataInfo.validate(
            async valid => {
              if (valid) {
                if (this.$refs.recruitInfo.caseDataInfo.wisdomLabelsChildren) {
                  // 领域
                  this.$refs.recruitInfo.caseDataInfo.neighborhoodTypes = this.$refs.recruitInfo.caseDataInfo.neighborhoodTypes.concat(
                    this.$refs.recruitInfo.caseDataInfo.wisdomLabelsChildren
                  );
                }
                if (this.$refs.recruitInfo.caseDataInfo.labelsChildren) {
                  //  技术
                  this.$refs.recruitInfo.caseDataInfo.technologyTypes = this.$refs.recruitInfo.caseDataInfo.technologyTypes.concat(
                    this.$refs.recruitInfo.caseDataInfo.labelsChildren
                  );
                }
                if (this.$refs.recruitInfo.caseDataInfo.sectorssChildren) {
                  // 行业
                  this.$refs.recruitInfo.caseDataInfo.industryTypes = this.$refs.recruitInfo.caseDataInfo.industryTypes.concat(
                    this.$refs.recruitInfo.caseDataInfo.sectorssChildren
                  );
                }

                if (this.projectDataType || this.addId) {
                  res = await updateRecruitment(
                    this.$refs.recruitInfo.caseDataInfo
                  );
                } else {
                  res = await insertRecruitment(
                    this.$refs.recruitInfo.caseDataInfo
                  );
                }

                if (res.code === 200) {
                  this.$message.success(
                    `${this.projectDataType ? "编辑" : "新增"}成功`
                  );

                  if (res.data !== "修改成功" && res.data !== "") {
                    this.addId = res.data;
                  }

                  this.activeName = "second";
                } else {
                  this.$message.error(res.msg);
                }
              } else {
                this.$message.error("请完善基本信息!");
              }
            }
          );
          break;
        case "second":
          //最终组装的数组
          let arr = [];
          //开始添加
          this.$refs.labelInfo.recruitmentLabelParam.recruitmentKeywordAddParamList.forEach(
            item => {
              let keyword = item.keyword;
              let keywordDescribe = item.keywordDescribe;
              let recruitmentLabelList = [];
              // 遍历我添加的数组
              item.recruitmentLabelList.forEach(child => {
                // 遍历总数组
                this.$refs.labelInfo.labelOptions.forEach(item1 => {
                  if (item1.labelKeywordResultList[0].id == child.labelId) {
                    item1.labelKeywordResultList.forEach(name => {
                      name = {
                        groupLabelId: name.groupLabelPrefix,
                        groupLabelName: name.groupLabelType,
                        labelId: name.id,
                        labelName: name.labelName,
                        labelStatus:
                          item1.labelKeywordResultList[0].id == name.id ? 1 : 0
                      };
                      recruitmentLabelList.push(name);
                    });
                  }
                });
              });

              let all = {
                keyword: keyword,
                keywordDescribe: keywordDescribe,
                recruitmentLabelList: recruitmentLabelList
              };
              arr.push(all);
            }
          );

          // 最终提交后端的数据
          let recruitmentLabelParam = {
            keywordVersion: this.$refs.labelInfo.recruitmentLabelParam
              .keywordVersion, //关键字版本
              recruitmentId: this.addId ? this.addId : this.id,
              recruitmentKeywordAddParamList: arr
          };
          // 判断是编辑还是新增 1编辑  0新增
          if (!this.projectDataType) {
            // 如果未保存基本信息
            if (!this.addId) {
              this.$message.error("请先保存基本信息的数据再进行下一步操作！");
            } else {
              this.$refs.labelInfo.$refs.labelInfoForm.validate(async valid => {
                if (valid) {
                  res = await insertRecruitmentLabel(
                    recruitmentLabelParam
                  );
                  if (res.code == 200) {
                    this.$message.success("新增成功!");
                    this.activeName = "third";
                  } else {
                    this.$message.error("新增失败，请稍候重试!");
                  }
                }
              });
            }
          } else {
            this.$refs.labelInfo.$refs.labelInfoForm.validate(async valid => {
              if (valid) {
                res = await updateRecruitmentLabel(
                  recruitmentLabelParam
                );
                if (res.code == 200) {
                  this.$message.success("编辑成功!");
                  this.activeName = "third";
                } else {
                  this.$message.error("编辑失败，请稍候重试!");
                }
              }
            });
          }
          break;
        case "third":
          if (this.id) {
            let query = [];

            this.$refs.labelManagement.radiolist.forEach(el => {
              if (el.labelparam.labelNameList.length > 0) {
                el.labelparam.recruitmentId = this.addId ? Number(this.addId) : Number(this.id);
                query.push(el.labelparam);
              }
              el.labelparam.labelNameList.forEach(item => {
                item.recruitmentId = this.addId ? Number(this.addId ) : Number(this.id);
              })
            });
            if (query.length > 0) {
              if (!this.$refs.labelManagement.labeList.length) {
                insertRecruitmentLabelManual(query).then(response => {
                  if (response.code == 200) {
                    this.$message.success("保存成功");
                    this.activeName = "four";
                  } else {
                    this.$message.error(response.data.message);
                  }
                });
              } else {
                updateRecruitmentLabelManual(query).then(response => {
                  if (response.code == 200) {
                    this.$message.success("编辑成功");
                    this.activeName = "four";
                  } else {
                    this.$message.error(response.data.message);
                  }
                });
              }
            } else {
              this.$message.warning("请勾选标签！");
            }
          } else {
            if (!this.addId) {
              this.$message.error("请先保存基本信息的数据再进行下一步操作！");
            } else {
              let query = [];

              this.$refs.labelManagement.radiolist.forEach(el => {
                if (el.labelparam.labelNameList.length > 0) {
                  el.labelparam.recruitmentId = this.addId ? Number(this.addId) : Number(this.id);
                  query.push(el.labelparam);
                }
                el.labelparam.labelNameList.forEach(item => {
                  item.recruitmentId = this.addId ? Number(this.addId ) : Number(this.id);
                })
              });
              if (query.length > 0) {
                if (!this.$refs.labelManagement.labeList.length) {
                  insertRecruitmentLabelManual(query).then(response => {
                    if (response.code == 200) {
                      this.$message.success("保存成功");
                      this.activeName = "four";
                    } else {
                      this.$message.error(response.data.message);
                    }
                  });
                } else {
                  updateRecruitmentLabelManual(query).then(response => {
                    if (response.code == 200) {
                      this.$message.success("编辑成功");
                      this.activeName = "four";
                    } else {
                      this.$message.error(response.data.message);
                    }
                  });
                }
              } else {
                this.$message.warning("请勾选标签！");
              }
            }
          }

          break;
        case "four":
          break;
        default:
          break;
      }
    },
    // 招聘数据-取消按钮
    cancelItem() {
      this.$router.push({
        path: "/recruit"
      });
    },
    // 招聘数据基本信息详情
    async search() {
      const res = await getPolicyDetail({ id: this.personDataId });
      if (res.code == 200) {
        this.caseDataInfo = res.data;
        if (this.caseDataInfo.industry) {
          this.caseDataInfo.industry = this.caseDataInfo.industry.split(",");
        } else {
          this.caseDataInfo.industry = [];
        }
        // if (this.caseDataInfo.institutionType) {
        //   this.caseDataInfo.institutionType =
        //     this.caseDataInfo.institutionType.split(",");
        // } else {
        //   this.caseDataInfo.institutionType = [];
        // }
        if (this.caseDataInfo.field) {
          this.caseDataInfo.field = this.caseDataInfo.field.split(",");
        } else {
          this.caseDataInfo.field = [];
        }
        if (this.caseDataInfo.technologyType) {
          this.caseDataInfo.technologyType = this.caseDataInfo.technologyType.split(
            ","
          );
        } else {
          this.caseDataInfo.technologyType = [];
        }
        if (this.caseDataInfo.policyFileName) {
          this.caseDataInfo.policyFileNames = this.caseDataInfo.policyFileName.substring(
            this.caseDataInfo.policyFileName.lastIndexOf("/") + 1
          );
        }
      }
      // 如果政策附件返回为空的情况，就默认复制为空字符串
      if (!res.data.policyFiles || !res.data.policyFiles.length > 0) {
        this.caseDataInfo.policyFiles = [""];
      }
      // 如果政策联系人返回为空的情况，就默认复制为空字符串
      if (!res.data.policyContacts || !res.data.policyContacts.length > 0) {
        this.caseDataInfo.policyContacts = [""];
      }
      // 如果联系电话返回为空的情况，就默认复制为空字符串
      if (!res.data.contactPhone || !res.data.contactPhone.length > 0) {
        this.caseDataInfo.contactPhone = [""];
      }
      // 如果联系邮箱返回为空的情况，就默认复制为空字符串
      if (!res.data.email || !res.data.email.length > 0) {
        this.caseDataInfo.email = [""];
      }
      // 如果邮寄联系人返回为空的情况，就默认复制为空字符串
      if (!res.data.mailingContact || !res.data.mailingContact.length > 0) {
        this.caseDataInfo.mailingContact = [""];
      }
    },
    // 招聘数据标签信息详情
    async selectRecruitmentLabelDetail() {
      const res = await selectRecruitmentLabelDetail({
        recruitmentId: this.id ? this.id : this.addId
      });
      if (res.code == 200) {
        // 如果请求的标签数据为null的情况，就处理下
        if (!res.data.recruitmentKeywordAddParamList) {
          this.$refs.labelInfo.recruitmentLabelParam = {
            recruitmentId: "", // 招聘主键
            keywordVersion: "", // 关键字版本
            // type: null,
            recruitmentKeywordAddParamList: [
              {
                recruitmentLabelList: [
                  {
                    groupLabelId: "", // 标签组编号
                    groupLabelName: "", // 标签组名称
                    labelId: "", // 标签编号
                    labelName: "", // 标签内容
                    reportKeywordId: null,
                    id: null,
                    superiorLabel: null // 上级标签
                  }
                ],
                keyword: "", // 关键词
                keywordDescribe: "" // 关键词数据
              }
            ]
          };
        } else {
          this.$refs.labelInfo.recruitmentLabelParam = res.data;
          // 请求数据回来后，将标签内容也改为（标签组名称+标签内容）
          res.data.recruitmentKeywordAddParamList.forEach(item => {
            item.recruitmentLabelList.forEach(child => {
              child.labelName = child.groupLabelName + "/" + child.labelName;
            });
          });
        }
      }
    }
  }
};
</script>

<style lang="less" scoped>
.caseDataParent_type {
  padding: 0 20px;
}
.caseDataParent {
  overflow: hidden;
  background-color: #fff;
  /deep/ .el-tabs__content {
    overflow: auto;
  }
}
.bottom {
  border-top: 1px solid #dbdbdb;
  background: #fff;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  .el-button {
    margin: 0 20px;
  }
}
</style>
